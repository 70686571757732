const AdminHome = () => import(/* webpackChunkName: "adminsection" */'./AdminHome.vue')
const AdminLogin = () => import(/* webpackChunkName: "adminsection" */'./AdminLogin.vue')

const ClientList = () => import(/* webpackChunkName: "adminsection" */'./clients/ClientList.vue')
const ClientForm = () => import(/* webpackChunkName: "adminsection" */'./clients/ClientForm.vue')
const ClientDetail = () => import(/* webpackChunkName: "adminsection" */'./clients/ClientDetail.vue')
const ClientActivity = () => import(/* webpackChunkName: "adminsection" */'./clients/ClientActivity.vue')
const ProjectList = () => import(/* webpackChunkName: "adminsection" */'./projects/ProjectList.vue')
const ProjectForm = () => import(/* webpackChunkName: "adminsection" */'./projects/ProjectForm.vue')
const ProjectDetail = () => import(/* webpackChunkName: "adminsection" */'./projects/ProjectDetail.vue')
const ShowcaseList = () => import(/* webpackChunkName: "adminsection" */'./showcases/ShowcaseList.vue')
const ShowcaseForm = () => import(/* webpackChunkName: "adminsection" */'./showcases/ShowcaseForm.vue')
const ShowcaseDetail = () => import(/* webpackChunkName: "adminsection" */'./showcases/ShowcaseDetail.vue')

export default [
    { path: '', component: AdminHome, meta: {title: 'Admin Home'} },
    { path: 'login', component: AdminLogin, meta: {title: 'Admin Login'}},
    { path: 'clients', component: ClientList, meta: {title: 'Client List'}},
    { path: 'clients/edit/:id', component: ClientForm, meta: {title: 'Add/Edit Client'}},
    { path: 'clients/:id', component: ClientDetail, meta: {title: 'View Client'}},
    { path: 'client-activity/', component: ClientActivity, meta: {title: 'Client Activity'}},
    { path: 'projects', component: ProjectList, meta: {title: 'Project List'}},
    { path: 'projects/edit/:id', component: ProjectForm, meta: {title: 'Add/Edit Projects'}},
    { path: 'projects/:id', component: ProjectDetail, meta: {title: 'View Project'}},
    { path: 'showcases', component: ShowcaseList, meta: {title: 'Showcase List'}},
    { path: 'showcases/edit/:id', component: ShowcaseForm, meta: {title: 'Add/Edit Showcase'}},
    { path: 'showcases/:id', component: ShowcaseDetail, meta: {title: 'View Showcase'}},
]
