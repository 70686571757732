<template>
    <nav class="app-nav">
        <header>
            <img src="./images/logo.svg" width="32">
            <span>Client Area</span>
            <label for="menuctl"><i class="icon">menu</i></label>
        </header>
        <input id="menuctl" type="checkbox" style="display: none;">
        <ul class="menu">
            <template v-if="user !== null && user.is_admin">
                <li><router-link to="/admin">Admin Home</router-link></li>
                <li>
                    <router-link to="/admin/clients">Clients</router-link>
                </li>
                <li>
                    <router-link to="/admin/projects">Projects</router-link>
                </li>
                <li>
                    <router-link to="/admin/showcases">Showcases</router-link>
                </li>
                <hr>
                <li>
                    <router-link to="/admin/client-activity">Client Activity</router-link>
                </li>
                <hr>
            </template>
            <template v-else-if="user !== null && !user.is_admin">
                <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/projects">Projects</router-link></li>
            </template>
            <li v-if="isLoggedIn"><a href="#" @click.prevent="logout">Log Out</a></li>
        </ul>
    </nav>
</template>

<style lang="scss">
@import "include-media";
nav.app-nav {
    background: #064260;
    color: #fefefe;

    header {
        background: rgba(0, 0, 0, 0.15);
        display: flex;
        flex-wrap: nowrap;
        padding: 0.5rem;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 300;

        img {
            margin-right: 0.5rem;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        display: block;
        ul {
            display: none;
        }
    }

    @include media('>tablet') {
        // width: 10rem;
        min-height: 100%;

        header label {
            display: none;
        }
        li {
            display: block;

            a {
                color: white;
                display: block;
                padding: 0.5rem 1rem;
                text-decoration: none;
                // border-bottom: solid #fefefe 1px;
            }
        }

        .router-link-exact-active {
            border-right: solid 6px white;
        }

        .router-link-active + ul {
            display: block;
            background: rgba(255, 255, 255, 0.15);
            background-blend-mode: overlay;

            li {
                font-size: 87.5%;
                a {
                    padding-left: 1.5rem;
                }
            }
        }
    }
    @include media('<=tablet') {
        // width: 100%;
        // height: 4rem;

        ul.menu {
            display: none;
            

            a {
                padding: 0.5rem 1rem;
                color: white;
                text-decoration: none;
                display: block;
            }
        }
        :checked + ul.menu {
            display: block;
        }

        header {
            span {
                flex: 1 0 auto;
            }
            i {
                vertical-align: text-bottom;
            }
        }
    }

}
</style>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
    name: 'app-navigation',
    computed: {
        ...mapGetters(['user']),
        isLoggedIn () {
            return this.user !== null
        },
    },
    methods: {
        async logout () {
            let auth2 = gapi.auth2.getAuthInstance()
            if (auth2.isSignedIn.get()) {
                await auth2.signOut()
            }

            await axios.get('/api/logout')

            this.$store.dispatch('setUser', null)
            // this.$router.push('/')
            window.location.reload()
        },
    },
    mounted () {

    },
}
</script>
