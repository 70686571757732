import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import * as types from './mutation-types'

Vue.use(Vuex)

const INTERVAL = 5 * 60 * 1000
const debug = process.env.NODE_ENV !== 'production'

const storagePlugin = store => {
    let saved_data = JSON.parse(window.sessionStorage.clientArea || '{}')
    saved_data.navigationVisible = true
    saved_data.activityLog = []
    let new_state = {}

    Object.assign(new_state, store.state, saved_data)
    store.replaceState(new_state)

    store.subscribe((mutation, state) => {
        try {
            window.sessionStorage.clientArea = JSON.stringify(state)
        } catch (e) {
            // i hate you safari
        }
    })
}

export default new Vuex.Store({
    strict: debug,
    plugins: [storagePlugin],
    state: {
        user: null,
        navigationVisible: true,
        activityLog: [],
        lastShipment: 0,
    },
    getters: {
        user: state => state.user,
        navigationVisible: state => state.navigationVisible,
        activityLog: state => state.activityLog,
        lastShipment: state => state.lastShipment,
    },
    actions: {
        setUser ({commit, getters}, data) {
            commit(types.USER, data)
        },
        toggleNavigation ({commit, getters}) {
            commit(types.TOGGLE_NAV)
        },
        logActivity ({commit, getters, dispatch}, data) {
            commit(types.LOG_ACTIVITY, data)

            if (getters.lastShipment == 0) {
                commit(types.RESET_COUNTER)
            }

            // let now = Date.now()
            // if (now - getters.lastShipment > INTERVAL) {
            //    dispatch('shipLogs')
            // }
        },
        sendBeacon ({commit, getters}) {
            if (getters.user.is_admin) {
                return;
            }

            let data = {
                client_activity: {
                    client_id: getters.user.id,
                    log: getters.activityLog,
                },
            }

            navigator.sendBeacon('/api/models/client-activities', JSON.stringify(data))
        },
        async shipLogs ({commit, getters}) {
            if (getters.user.is_admin) {
                return;
            }

            let data = {
                client_activity: {
                    client_id: getters.user.id,
                    log: getters.activityLog,
                },
            }

            try {
                await axios.post('/api/models/client-activities', data)
            }
            catch (e) {
            }

            commit(types.CLEAR_LOG)
            commit(types.RESET_COUNTER)
        },
    },
    mutations: {
        [types.USER] (state, data) {
            state.user = data
        },
        [types.TOGGLE_NAV] (state) {
            state.navigationVisible = !state.navigationVisible
        },
        [types.LOG_ACTIVITY] (state, data) {
            data['time'] = Date.now()
            state.activityLog.push(data)
        },
        [types.CLEAR_LOG] (state) {
            state.activityLog = []
        },
        [types.RESET_COUNTER] (state) {
            state.lastShipment = Date.now()
        },
    }
})
