import './scss/clientarea.scss'
import 'dialog-polyfill/dialog-polyfill.css'
import 'babel-polyfill'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import axios from 'axios'
import fscreen from 'fscreen'

import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex, { mapGetters } from 'vuex'

import store from './store'

import AppNotify from 'app-notify'

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(AppNotify)

import AppNavigation from './AppNavigation.vue'

import adminRoutes from './admin/routes.js'
import clientRoutes from './client/routes.js'

// const AdminSection = () => import(/* webpackChunkName: "adminsection" */'./AdminSection.vue')
// const ClientSection = () => import(/* webpackChunkName: "clientsection" */'./ClientSection.vue')

const AdminSection = {template: '<section class="admin"><router-view></router-view></section>'}
const ClientSection = {template: '<section class="client"><router-view></router-view></section>'}

const routes = [
    {
        path: '/',
        component: ClientSection,
        children: clientRoutes,
        beforeEnter: (to, from, next) => {
            let user = store.getters.user
            if (!user && to.path != '/login') {
                sessionStorage.after_login = to.path
                next('/login')
                return
            }
            next()
        },
        meta: { title: 'Client Login', }
    },
    {
        path: '/ql/:id',
        beforeEnter: async (to, from, next) => {
            let res
            try {
                res = await axios.get(`/api/ql/${to.params.id}`)
            }
            catch (e) {
                console.error(e)
                next('/')
                return
            }
            next(res.data.url)
            
        },
    },
    { 
        path: '/admin', 
        component: AdminSection, 
        children: adminRoutes,
        beforeEnter: (to, from, next) => {
            let user = store.getters.user

            if (!user && to.path != '/admin/login') {
                next('/admin/login')
                return
            }
            else if (user && !user.is_admin) {
                // console.log(to)
                // block non-admin use
            }

            next()
        },
        meta: { title: 'Admin Login', }
    },
]


const router = new VueRouter({
    mode: 'history',
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = `${to.meta.title} | Digital Eye Client Area`
    }
    else {
        document.title = 'Digital Eye Client Area'
    }
    next();
});

const notify = new AppNotify()

const localeOptions = new Intl.DateTimeFormat().resolvedOptions()

Vue.filter('fmtdate', (value, fmt='MM/DD/YYYY h:mm:ss a') => {
    if (value === null || value === undefined) {
        return 'Never'
    }

    let date = parse(value)
    let parts = Intl.DateTimeFormat(localeOptions.locale, {timeZoneName: 'short'}).formatToParts(date)

    let tz = parts.find(p => p.type == 'timeZoneName')
    
    return `${format(date, fmt)} ${tz.value}`
})

//Fix for chrome
fscreen.addEventListener('fullscreenchange', (event) => {
    if (fscreen.fullscreenElement === null) {
        let n = document.querySelector('#app > .app-nav')
        let s = document.querySelector('#app > section')

        n.style.height = '100%'
        s.style.height = '100%'

        setTimeout(() => {
            n.style.height = ''
            s.style.height = ''
        }, 10)
    }
});

gapiPromise.then(() => {
    const app = new Vue({
        router,
        store,
        notify,
        components: { AppNavigation },
        computed: {
            ...mapGetters(['navigationVisible']),
        },
    }).$mount('#app')
    
    window.addEventListener('unload', (event) => {
        store.dispatch('logActivity', {
            type: 'Window closed',
        })
        store.dispatch('sendBeacon')
    });

    axios.interceptors.response.use((response) => {
        return response;
    }, async (error) => {
        let ignore = [401, 403, 404]
        if (ignore.indexOf(error.data.response.status) != -1) {
            await axios.post('/api/log', {data: error})
        }

        return Promise.reject(error);
    });
})

