const ClientHome = () => import(/* webpackChunkName: "clientsection" */'./ClientHome.vue')
const ClientLogin = () => import(/* webpackChunkName: "clientsection" */'./ClientLogin.vue')
const GalleryPage = () => import(/* webpackChunkName: "clientsection" */'./GalleryPage.vue')
const SitePage = () => import(/* webpackChunkName: "clientsection" */'./SitePage.vue')
const ProjectList = () => import(/* webpackChunkName: "clientsection" */'./ProjectList.vue')
const ProjectDetail = () => import(/* webpackChunkName: "clientsection" */'./ProjectDetail.vue')

export default [
    { path: '', component: ClientHome },
    { path: '/login', component: ClientLogin },
    { path: '/projects', component: ProjectList },
    { path: '/project/:id', component: ProjectDetail },
    { path: '/view-gallery/:id', component: GalleryPage },
    { path: '/view-site/:id', component: SitePage },
]
